import * as React from "react"
import Brand from "./brand"
import { Disclosure } from "@headlessui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { Link } from "gatsby"

const Navigation: React.FC = (): React.ReactElement => {
  return (
    <nav className="bg-deep-navy-900 w-full sticky top-0 z-50">
      <div className="my-4">
        <div className="mx-auto px-2 sm:px-4 lg:px-6 lg:max-w-8xl flex justify-around">
          <div className="flex justify-between">
            <Brand className="mr-4 md:mr-6" />
            <div className="hidden xs:block ring-0 text-white inline-block w-7 h-7 mr-4 md:mr-6 hover:text-tangerine hover:ring-tangerine items-center flex justify-center rounded-sm">
              <a href="https://www.twitter.com/@payroctech">
                <FontAwesomeIcon icon={faLinkedin} size="lg" />
              </a>
            </div>
            <div className="hidden xs:block ring-0 text-white inline-block w-7 h-7 mr-4 md:mr-6 hover:text-tangerine hover:ring-tangerine items-center flex justify-center rounded-sm">
              <a href="https://www.twitter.com/@payroctech">
                <FontAwesomeIcon icon={faTwitter} size="lg" />
              </a>
            </div>
          </div>
          <div className="flex flex-grow justify-end">
            <div>
              <Link
                to="/internship-2023"
                className="block bg-lavender hover:bg-deep-navy-700 dark:hover:bg-cloud-navy py-2 px-2 xs:px-6 text-white rounded-md font-rocgrotesk w-full"
              >
                Join our internship
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navigation
