import * as React from "react"
import Navigation from "../components/navigation"
import Hero from "../components/hero"
import { Link } from "gatsby"
import IframeResizer from "iframe-resizer-react"

import GraphicMoreImportantThings from "../images/graphic_more_time_important_740x566.png"
import GraphicSolveProblems from "../images/graphic_solve_problems_740x566.png"
import GraphicItsOk from "../images/graphic_its_ok_740x740.png"
import GraphicPaulVienneau from "../images/graphic_paul_vienneau_64x64.png"
import GraphicCareerGrowth from "../images/graphic_career_growth_740x566.png"

import ResponsiveContainer from "../components/responsive-container"
import { faArrowRight, faCheck, faChevronDown, faTimes } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { Disclosure, Dialog, Transition } from "@headlessui/react"

import { useState, Fragment } from "react"

import {
  UkFlexibleHolidayLeave,
  UsaFlexibleHolidayLeave,
  CaFlexibleHolidayLeave,
  RoiFlexibleHolidayLeave,
} from "../components/flexible-holiday-leave"

import {
  UkHealthInsurance,
  UsaHealthInsurance,
  UsaHealthSavings,
  UsaAdditionalHealth,
  CaHealthInsurance,
  RoiHealthInsurance,
} from "../components/free-health-insurance"

import {
  UkRemoteWorkingAllowance,
  UsaRemoteWorkingAllowance,
  CaRemoteWorkingAllowance,
  RoiRemoteWorkingAllowance,
} from "../components/remote-working-allowance"

import {
  UkGroupPensionScheme,
  UsaGroupPensionScheme,
  CaGroupPensionScheme,
  RoiGroupPensionScheme,
} from "../components/group-pension-scheme"

import { AnnualBonusPlan } from "../components/annual-bonus"

import { UkReferralPolicy, UsaReferralPolicy, CaReferralPolicy, RoiReferralPolicy } from "../components/referral-policy"

import { PaidVolunteering } from "../components/paid-volunteering"

const benefitsTable = {
  uk: {
    title: "United Kingdom",
    items: [
      <UkFlexibleHolidayLeave />,
      <UkHealthInsurance />,
      <UkRemoteWorkingAllowance />,
      <UkGroupPensionScheme />,
      <AnnualBonusPlan />,
      <UkReferralPolicy />,
      <PaidVolunteering />,
    ],
  },
  usa: {
    title: "United States",
    items: [
      <UsaFlexibleHolidayLeave />,
      <UsaRemoteWorkingAllowance />,
      <UsaGroupPensionScheme />,
      <AnnualBonusPlan />,
      <UsaHealthInsurance />,
      <PaidVolunteering />,
      <UsaHealthSavings />,
      <UsaAdditionalHealth />,
      <UsaReferralPolicy />,
    ],
  },
  ca: {
    title: "Canada",
    items: [
      <CaFlexibleHolidayLeave />,
      <AnnualBonusPlan />,
      <CaRemoteWorkingAllowance />,
      <CaGroupPensionScheme />,
      <CaHealthInsurance />,
      <PaidVolunteering />,
      <CaReferralPolicy />,
    ],
  },
  roi: {
    title: "Republic of Ireland",
    items: [
      <RoiFlexibleHolidayLeave />,
      <RoiGroupPensionScheme />,
      <AnnualBonusPlan />,
      <RoiRemoteWorkingAllowance />,
      <PaidVolunteering />,
      <RoiHealthInsurance />,
      <RoiReferralPolicy />,
    ],
  },
}

const testimonials = [
  {
    name: "Terry Mace",
    title: "Senior Software Engineer II",
    location: "United Kingdom",
    lengthOfService: "10 months",
    description: [
      "I’ve worked for Payroc for 10 months and in that time, I have worked on a wide variety of projects and even been promoted!",
      "I have had the opportunity to work on a green field project building a logging library for our suite of applications, I’ve upgraded a large customer facing application to .NET 6 and I have integrated our applications with Salesforce.",
      "We typically work in teams of around 4 engineers plus a test engineer. Each team is very self-sufficient with the flexibility to work in a way that suits its team members. Being project based, we get to see a new feature go from concept all the way to deployment.",
      "Payroc has fully embraced remote working. There are lots of initiatives to bring us together; whether that’s hackathons, community guilds or watercooler Teams meetings.",
      "It’s great to work somewhere that recognises talent, embraces modern working practices, and challenges us to strive for technical excellence.",
    ],
  },
  {
    name: "Laura Porter",
    title: "Information Security Analyst II",
    location: "United Kingdom",
    lengthOfService: "18 months",
    description: [
      "I have been at Payroc for a year and a half and in that time I feel I have made my mark. Between analysing threats, vulnerabilities and securing new mergers and acquisitions, I am also able to develop my skills and further my knowledge by obtaining the OSCP certification.",
      "I enjoy being given the freedom and trust to work independently to provide solutions to issues using the technologies or tools I feel fit best. Working alongside a great team is an added bonus.",
      "Remote working and flexible PTO are also major reasons why I enjoy working at Payroc, it allows me to save time and money I would usually spend commuting and put that back into other interests or hobbies.",
    ],
  },
  {
    name: "Parrish Husband",
    title: "Principal Software Engineer I",
    location: "United States",
    lengthOfService: "10 months",
    description: [
      "I joined Payroc back in July of 2021, having worked remotely for several years at a small software company based in Texas.  Having felt isolated in my previous role, in the summer of 2021 I began searching for a new opportunity with the goal of rounding out my own knowledge gaps and collaborating with other developers on new and interesting technologies I had seen being adopted.  I am pleased to report after 10 months at Payroc that I've been able to gain a tremendous amount of knowledge and learn from incredibly talented people across all disciplines of the software development lifecycle.",
      "I find the culture here is incredibly supportive of asking questions and promotes continual learning and collaboration.  I appreciate the diverse skillset across the entire technology team and am constantly meeting new people who show me different perspectives on problems or explain things I'm having trouble understanding. ",
      "Payroc is also challenging me in ways I hadn't anticipated, which is giving me opportunities to improve how I share information and collaborate with others.  I have had to pick up a lot of domain knowledge and adapt to new technologies/processes, but Payroc is giving me the tools and space I need to get the job done.  ",
      "Coming from a long background of remote development work, it's clear to me the company understands the benefits and challenges this can pose and have done a fantastic job promoting a healthy work/life balance, while also maintaining the structure needed for collaboration across time zones.  I am looking forward to new and interesting projects on the horizon and am excited to see all our collective efforts coming into fruition.",
    ],
  },
  {
    name: "Fiona McDaid",
    title: "Principal Software Engineer I",
    location: "United Kingdom",
    lengthOfService: "5 months",
    description: [
      "From day 1, Payroc provided everything I needed to be productive in my role. The smooth onboarding process ensured I settled in and felt at home straight away. Everyday I get to work with a great bunch of passionate engineers who are motivated to achieve our shared goals.",
      "The remote working model enhances my work life balance giving me flexibility which I value as a working parent. On top of that, the Flex PTO policy means I don’t need to ‘save’ my time off but can take time as and when needed.",
      "Respect, care and a people first approach are qualities that flow through Payroc from the top. I am empowered to make decisions and have the right encouragement and mentorship supporting me to succeed.",
    ],
  },
  {
    name: "Betwin Zanganeh",
    title: "Software Engineer II",
    location: "United Kingdom",
    lengthOfService: "6 months",
    description: [
      "My favourite thing about working at Payroc is the incredible opportunities for growth; both for my own and the company. They've seen my potential and encouraged me to dive deeper and really take ownership of my tasks. I really feel I am contributing to the successes of the company.",
      "With 2 years of software engineering experience, I find that Payroc provides the best of both worlds. I am challenged on both a technical level and in my soft-skills every day. I am always doing meaningful work and have the freedom and responsibility that comes with working from home.",
      "Payroc embraced my decision to travel to see family abroad while working with open arms, this is alongside providing unlimited holiday. Payroc leads and paves the path for what working from home truly means! There is a true sense of maturity within the company, managers/directors trust the work I do while allowing mistakes to happen and everyone's opinion is taken into consideration.",
      "There is a sense of family within Payroc and I believe anyone that joins would be grateful to be working alongside such an amazing team.",
    ],
  },
  {
    name: "Christina Porter",
    title: "Senior Software Engineer II",
    location: "Canada",
    lengthOfService: "6 months",
    description: [
      "When I joined Payroc, I was looking for a job where I felt valued and appreciated. I wanted to be part of a world-class software engineering team doing exceptional work. The fantastic team at Payroc is doing just that. Payroc offers competitive compensation with a clear path for career advancement. They provide a healthy work-life balance with flexible remote work and unlimited personal time off.",
      "Payroc is an environment where I can advance my own skills to keep up with the ever-changing software industry. The company provides many opportunities to learn and grow through lunch & learns, a catalog of online courses, and supports my personal growth through skill building events like hackathons. I also love that the teams at Payroc have been incredibly supportive, making administrative tasks easy, so that our team can focus on what we do best, building great software.",
    ],
  },
  {
    name: "Eric Strong",
    title: "Senior DevOps Engineer II",
    location: "Republic of Ireland",
    lengthOfService: "8 months",
    description: [
      "My first 6 months with Payroc has been exciting, rewarding and a fantastic learning experience. Everybody is welcoming and I work with some of the smartest people in technology.",
      "Everyday is different. From day one I have been integrating with Development teams and assisting with the continuous integration, delivery of our products.",
      "Since I joined I have been working with different cloud technologies, onprem technologies, scripting and automating some of the more coolest technology. This has helped me learn and grow and helped me to utilize my skills as a DevOps engineer.",
      "Since joining we have introduced many re-usable patterns for DevOps and supported teams with our DevOps principals and helped influence a collaborative culture.",
      "This has been an enjoyable experience and one that feels like you have a real input to influencing decision making.",
    ],
  },
  {
    name: "Kaelie Black",
    title: "Senior Test Engineer II",
    location: "United Kingdom",
    lengthOfService: "7 months",
    description: [
      "I joined Payroc 7 months ago and in this time, I have worked on a variety of projects including updates to existing systems and Payroc’s new universal API solution. I have even had the opportunity to help install a new test automation framework and develop my automation skills, something I have wanted to do for the last few years but not been able to in previous companies.",
      "At Payroc, I truly believe that everyone has a voice, and everyone’s opinion is respected and taken into consideration.  The senior management have a very ‘open door’ policy and go to great lengths to get employee feedback and action suggestions. Payroc has fully embraced remote working but despite this there is still a great ‘team’ feeling and we are always reminded that ‘It’s ok’ to have a work/life balance, to ask questions and to make mistakes.",
      "I’m looking forward to seeing what the future holds at Payroc.",
    ],
  },
]

// markup
const IndexPage: React.FC = (): React.ReactElement => {
  const [benefits, setBenefits] = useState({ country: "uk", open: false })

  function closeBenefitsModal() {
    setBenefits({ ...benefits, open: false })
  }

  function openBenefitsModal(country: string) {
    setBenefits({ country, open: true })
  }

  const [testimonial, setTestimonial] = useState({ testimonial: testimonials[0], open: false })

  function closeTestimonialModal() {
    setTestimonial({ ...testimonial, open: false })
  }

  function openTestimonialModal(testimonial: any) {
    setTestimonial({ testimonial, open: true })
  }

  return (
    <>
      <main className="light min-h-full  min-w-[350px]">
        <div className="bg-white dark:bg-deep-navy-800 h-full min-h-full flex flex-col font-sans">
          <section className="flex">
            <Navigation />
          </section>

          <Hero />

          <section className="my-24 relative">
            <div className="w-full h-full absolute top-0 left-0 z-0">
              <div className="bg-sky-blue left-0 right-1/3 top-0 bottom-0 absolute" />
            </div>
            <ResponsiveContainer className="bg-none relative">
              <div className="flex flex-col lg:flex-row items-center justify-between my-8">
                <div className="lg:max-w-lg xl:max-w-xl md:pr-12">
                  <h6 className="font-rocgrotesk text-lavender text-base my-2">REMOTE WORKING AND FLEXIBLE HOURS</h6>
                  <h1 className="font-rocgrotesk-bold text-3xl leading-relaxed my-2">Spend more time doing what's important to you</h1>
                  <div className="font-light my-8">
                    Our technology team are distributed across the UK, US, Canada and the Republic of Ireland. With remote working and
                    flexible hours, our team have more time to spend doing the things they love.
                  </div>
                  <div className="flex my-8">
                    <div className="w-full lg:w-auto">
                      <Link
                        to="#open-roles"
                        className="block text-center bg-tangerine hover:bg-deep-navy-900 dark:hover:bg-cloud-navy py-2 px-6 text-white rounded-md font-rocgrotesk w-full"
                      >
                        View current openings
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="flex lg:py-8">
                  <img src={GraphicMoreImportantThings} alt="There are more important things" />
                </div>
              </div>
            </ResponsiveContainer>
          </section>

          <section className="my-16 relative">
            <div className="w-full h-full absolute top-0 left-0 z-0">
              <div className="bg-sky-blue left-0 right-0 lg:left-1/3 lg:right-0 top-0 bottom-0 absolute" />
            </div>
            <ResponsiveContainer className="bg-none relative">
              <div className="flex flex-col flex-col-reverse lg:flex-row items-center justify-between my-8">
                <div className="flex lg:py-8">
                  <img src={GraphicSolveProblems} alt="We solve problems" />
                </div>
                <div className="lg:max-w-lg xl:max-w-xl md:pl-12">
                  <h6 className="font-rocgrotesk text-lavender text-base my-2">WHAT WE DO</h6>
                  <h1 className="font-rocgrotesk-bold text-3xl leading-relaxed my-2">We solve problems and build solutions</h1>
                  <div className="font-light my-8">
                    <p className="my-8">
                      We are at the very start of a journey that will reshape our technology stacks, platform, product and hopefully the
                      payments landscape, making it easier for people to process payments.
                    </p>
                    <p>
                      We're recruiting across multiple disciplines at all levels. There could be a role for you! Help us design and build
                      the next generation of product.
                    </p>
                  </div>
                  <div className="flex my-8">
                    <div className="w-full lg:w-auto">
                      <Link
                        to="#open-roles"
                        className="block text-center bg-tangerine hover:bg-deep-navy-900 dark:hover:bg-cloud-navy py-2 px-6 text-white rounded-md font-rocgrotesk w-full"
                      >
                        View current openings
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </ResponsiveContainer>
          </section>

          <section className="my-16 relative">
            <div className="w-full h-full absolute top-0 left-0 z-0">
              <div className="bg-sky-blue left-0 right-0 top-0 bottom-0 absolute" />
            </div>
            <ResponsiveContainer className="bg-none relative">
              <div className="items-center my-24">
                <div className="">
                  <h6 className="font-rocgrotesk text-lavender text-base my-2">TESTIMONIALS</h6>
                  <h1 className="font-rocgrotesk-bold text-3xl leading-relaxed my-2">What our employees say about Payroc</h1>
                  <div className="font-light my-8 lg:max-w-2xl xl:max-w-3xl">
                    Our employees happily share their thoughts on the work and on life at Payroc. Find out why our team loves working with
                    us.
                  </div>
                  <div className="flex w-full my-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8 w-full">
                      {testimonials.map((t) => (
                        <div
                          className="flex bg-white p-4 rounded-md shadow-xl space-x-4 justify-around group cursor-pointer"
                          onClick={() => openTestimonialModal(t)}
                        >
                          <div className="flex-col flex-1 justify-between items-center">
                            <p>
                              <span className="font-rocgrotesk-bold">{t.name}</span>
                              <span className="font-rocgrotesk-light"> ({t.lengthOfService})</span>
                            </p>
                            <p className="font-rocgrotesk-light tracking-tight">{t.title}</p>
                            <p className="font-rocgrotesk-light tracking-tight">{t.location}</p>
                          </div>
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            size="lg"
                            className="text-tangerine group-hover:transform group-hover:-rotate-45 my-auto mr-0"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </ResponsiveContainer>
          </section>

          <section className="my-16 relative">
            <div className="w-full h-full absolute top-0 left-0 z-0">
              <div className="bg-sky-blue left-0 right-0 lg:left-1/3 lg:right-0 top-0 bottom-0 absolute" />
            </div>
            <ResponsiveContainer className="bg-none relative">
              <div className="flex flex-col lg:flex-row items-center justify-between my-8">
                <div className="flex lg:py-8">
                  <img src={GraphicItsOk} alt="Its ok" />
                </div>
                <div className="lg:max-w-lg xl:max-w-xl md:pl-12">
                  <h6 className="font-rocgrotesk text-lavender text-base my-2">OUR ETHOS</h6>
                  <h1 className="font-rocgrotesk-bold text-3xl leading-relaxed my-2">It's OK</h1>
                  <div className="font-light my-8">
                    <p className="my-4">
                      At Payroc it's OK to be human! Our ethos allows the team to be open, stay curious, and to express themselves freely.
                    </p>
                    <p className="my-4">
                      We work hard to create a culture of creativity and innovation where you can experience a truly contagious spirit of
                      enthusiasm, pride, and relentless service. Payroc’s core values are the foundation of our success!
                    </p>
                  </div>
                  <div className="bg-white p-6 rounded-md shadow-xl">
                    <div className="font-rocgrotesk-light text-light text-base mb-4">
                      “The flexible work culture and remote working helps give an excellent work-life balance. There is a genuine desire to
                      help you develop and become the best you can be.”
                    </div>
                    <div className="flex mt-4">
                      <img src={GraphicPaulVienneau} alt="Paul Vienneau | Chief Technical Officer" />
                      <div className="flex flex-col justify-center pl-4 gap-4">
                        <p className="font-rocgrotesk-bold">Paul Vienneau</p>
                        <p className="font-rocgrotesk-light">Chief Technology Officer</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ResponsiveContainer>
          </section>

          <section className="my-16 relative">
            <div className="w-full h-full absolute top-0 left-0 z-0">
              <div className="bg-sky-blue left-0 right-0 top-0 bottom-0 absolute" />
            </div>
            <ResponsiveContainer className="bg-none relative">
              <div className="items-center my-24">
                <div className="">
                  <h6 className="font-rocgrotesk text-lavender text-base my-2">BENEFITS</h6>
                  <h1 className="font-rocgrotesk-bold text-3xl leading-relaxed my-2">What we offer our employees</h1>
                  <div className="font-light my-8 lg:max-w-2xl xl:max-w-3xl">
                    We offer a range of competitive benefits to everyone in the Technology team, including unlimited leave and paid bonuses.
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8">
                    <div
                      className="bg-white p-6 rounded-md shadow-xl flex justify-between items-center cursor-pointer group"
                      onClick={() => openBenefitsModal("uk")}
                    >
                      <div className="font-rocgrotesk-light text-light text-base">United Kingdom</div>
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        size="lg"
                        className="text-tangerine group-hover:transform group-hover:-rotate-45"
                      />
                    </div>
                    <div
                      className="bg-white p-6 rounded-md shadow-xl flex justify-between items-center cursor-pointer group"
                      onClick={() => openBenefitsModal("usa")}
                    >
                      <div className="font-rocgrotesk-light text-light text-base">United States</div>
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        size="lg"
                        className="text-tangerine group-hover:transform group-hover:-rotate-45"
                      />
                    </div>
                    <div
                      className="bg-white p-6 rounded-md shadow-xl flex justify-between items-center cursor-pointer group"
                      onClick={() => openBenefitsModal("ca")}
                    >
                      <div className="font-rocgrotesk-light text-light text-base">Canada</div>
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        size="lg"
                        className="text-tangerine group-hover:transform group-hover:-rotate-45"
                      />
                    </div>
                    <div
                      className="bg-white p-6 rounded-md shadow-xl flex justify-between items-center cursor-pointer group"
                      onClick={() => openBenefitsModal("roi")}
                    >
                      <div className="font-rocgrotesk-light text-light text-base">Republic of Ireland</div>
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        size="lg"
                        className="text-tangerine group-hover:transform group-hover:-rotate-45"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ResponsiveContainer>
          </section>

          <section className="my-16 relative">
            <div className="w-full h-full absolute top-0 left-0 z-0">
              <div className="bg-sky-blue left-0 right-1/3 top-0 bottom-0 absolute" />
            </div>
            <ResponsiveContainer className="bg-none relative">
              <div className="flex flex-col lg:flex-row items-center justify-between my-8">
                <div className="lg:max-w-lg xl:max-w-xl md:pr-12">
                  <h6 className="font-rocgrotesk text-lavender text-base my-2">CAREER GROWTH</h6>
                  <h1 className="font-rocgrotesk-bold text-3xl leading-relaxed my-2">Develop your career</h1>
                  <div className="font-light my-8">
                    <div className="flex my-4">
                      <FontAwesomeIcon icon={faCheck} size="1x" className="mt-1 text-tangerine mr-4 float-left" />
                      We’ve got remote-first working nailed. It’s a different experience developing your career in a distributed team, but
                      our remote working strategy and benefits help our team thrive
                    </div>
                    <div className="flex my-4">
                      <FontAwesomeIcon icon={faCheck} size="1x" className="mt-1 text-tangerine mr-4 float-left" />
                      Stay connected with regular virtual social events and stay inspired with hackathons and creative working groups.
                    </div>
                    <div className="flex my-4">
                      <FontAwesomeIcon icon={faCheck} size="1x" className="mt-1 text-tangerine mr-4 float-left" />
                      We’ve got you covered with plenty of learning and mentoring opportunities that mean you won’t miss out on the chance
                      to grow and develop.
                    </div>
                  </div>
                  <div className="flex my-8">
                    <div className="w-full lg:w-auto">
                      <Link
                        to="#open-roles"
                        className="block text-center bg-tangerine hover:bg-deep-navy-900 dark:hover:bg-cloud-navy py-2 px-6 text-white rounded-md font-rocgrotesk w-full"
                      >
                        View current openings
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="flex lg:py-8">
                  <img src={GraphicCareerGrowth} alt="Develop your career" />
                </div>
              </div>
            </ResponsiveContainer>
          </section>

          <section className="my-16 relative">
            <div className="w-full h-full absolute top-0 left-0 z-0">
              <div className="bg-sky-blue left-0 right-0 top-0 bottom-0 absolute" />
            </div>
            <ResponsiveContainer className="bg-none relative py-8">
              <div className="flex flex-col flex-shrink-0 lg:flex-row justify-between my-8">
                <div className="basis-1/2 md:pr-12">
                  <h6 className="font-rocgrotesk text-lavender text-base my-2">FAQS</h6>
                  <h1 className="font-rocgrotesk-bold text-3xl leading-relaxed my-2">Have a question?</h1>
                  <div className="font-light my-8 lg:max-w-2xl xl:max-w-3xl">
                    <p className="my-8">You can find some of the most commonly asked questions from previous applicants answered here.</p>
                    <p className="my-8">
                      We're happy to answer any further questions you may have. Drop us an email at{" "}
                      <a href="mailto:techrecruitement@payroc.com" className="text-tangerine underline">
                        techrecruitment@payroc.com
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div className="flex flex-col lg:py-8 basis-1/2">
                  <Disclosure as="div" className="bg-white p-2 my-2">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left hover:text-tangerine">
                          <span>I have no experience in the payments industry, should that put me off applying?</span>
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className={`${open ? "transform -rotate-90" : ""} w-5 h-5  text-tangerine ml-2`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 font-light">
                          If you have the relevant technical skills you shouldn’t be put off applying as we provide training to get you up
                          to speed.
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure as="div" className="bg-white p-2 my-2">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left hover:text-tangerine">
                          <span>What is the interview process?</span>
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className={`${open ? "transform -rotate-90" : ""} w-5 h-5 text-tangerine ml-2`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 font-light">
                          Our interview process is two stages, and could be completed within a few days.
                          <ol className="list-decimal list-inside">
                            <li>Informal interview focusing on skills and approach.</li>
                            <li>Technical interview which you can prepare for in advance and discuss with a panel.</li>
                          </ol>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure as="div" className="bg-white p-2 my-2">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left hover:text-tangerine">
                          <span>I have never worked remotely before, how does it all work?</span>
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className={`${open ? "transform -rotate-90" : ""} w-5 h-5 text-tangerine ml-2`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 font-light">
                          <p className="mb-4">
                            We make sure you get the latest Macbook or Windows Laptop (your choice) ahead of your start date. You also get
                            dual 27-inch monitors, keyboard, mouse and headset. On top of that, there is £500 home-office setup allowance so
                            you can be comfortable while you work.{" "}
                          </p>
                          <p className="mb-4">
                            During your first few weeks with us, we help you work through our onboarding process, connecting you with with
                            various people throughout the company, and arming you with the tools you need to be successful.{" "}
                          </p>
                          <p>
                            We rely heavily on Microsoft teams to keep communication flowing in Payroc. Between messaging, video calls, ask
                            me anything sessions run every two weeks etc, you’ll be kept in the loop.
                          </p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure as="div" className="bg-white p-2 my-2">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left hover:text-tangerine">
                          <span>I dont see a current role the suits me?</span>
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className={`${open ? "transform -rotate-90" : ""} w-5 h-5 text-tangerine ml-2`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 font-light">
                          Payroc look out for the best talent. Don’t be put off from{" "}
                          <a href="mailto:techrecruitment@payroc.com" className="text-tangerine underline">
                            sending in your CV
                          </a>{" "}
                          if you don’t see a role that matches your skill set. We are open to having a conversation with talented people.
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure as="div" className="bg-white p-2 my-2">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left hover:text-tangerine">
                          <span>I have another question about working at Payroc...</span>
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className={`${open ? "transform -rotate-90" : ""} w-5 h-5 text-tangerine ml-2`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 font-light">
                          We are a pretty open and honest bunch (its one of our values), so please&nbsp;
                          <a href="mailto:techrecruitment@payroc.com" className="text-tangerine underline">
                            ask us anything
                          </a>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
              </div>
            </ResponsiveContainer>
          </section>

          <section>
            <ResponsiveContainer className="bg-none relative py-8">
              <div className="flex flex-col flex-shrink-0 lg:flex-row justify-center my-8">
                <div className="pb-8 text-center">
                  <h6 className="font-rocgrotesk text-lavender text-base my-2">JOIN OUR TEAM</h6>
                  <h1 className="font-rocgrotesk-bold text-3xl leading-relaxed my-2" id="open-roles">
                    Current Openings
                  </h1>
                </div>
                <div className="flex my-8 md:hidden">
                  <div className="w-full lg:w-auto">
                    <a
                      href="https://recruiterflow.com/payroc/jobs#menu"
                      className="block text-center bg-tangerine hover:bg-deep-navy-900 dark:hover:bg-cloud-navy py-2 px-6 text-white rounded-md font-rocgrotesk w-full"
                    >
                      View our open job roles
                    </a>
                  </div>
                </div>
              </div>
            </ResponsiveContainer>
          </section>

          <section className="relative hidden md:block">
            <IframeResizer
              id="recruiterflow-jobs-iframe"
              src="https://recruiterflow.com/payroc/jobs-page-widget"
              className="min-w-full border-none"
            />
            <div className="h-16 bg-white absolute bottom-0 right-0 left-0" />
          </section>
        </div>
      </main>
      <footer>
        <ResponsiveContainer className="border-t border-t-gray-300 text-center p-4 md:my-16 font-rocgrotesk-light">
          Copyright &copy; Payroc 2022
        </ResponsiveContainer>
      </footer>

      <Transition appear show={testimonial.open} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-20 overflow-y-auto" onClose={closeTestimonialModal}>
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-4xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                <div className="flex justify-end">
                  <button onClick={closeTestimonialModal} className="w-6 h-6 text-center">
                    <div className="flex flex-col items-center">
                      <FontAwesomeIcon icon={faTimes} />
                      <span>esc</span>
                    </div>
                  </button>
                </div>

                <Dialog.Title as="h3" className="text-2xl font-rocgrotesk">
                  {testimonial.testimonial.name}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-lg font-rocgrotesk-light text-tangerine">{testimonial.testimonial.title}</p>
                </div>
                <div className="flex-col space-y-4 my-6">
                  {testimonial.testimonial.description.map((d) => (
                    <p>{d}</p>
                  ))}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={benefits.open} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-20 overflow-y-auto" onClose={closeBenefitsModal}>
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-4xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                <div className="flex justify-end">
                  <button onClick={closeBenefitsModal} className="w-6 h-6 text-center">
                    <div className="flex flex-col items-center">
                      <FontAwesomeIcon icon={faTimes} />
                      <span>esc</span>
                    </div>
                  </button>
                </div>

                <Dialog.Title as="h3" className="text-2xl font-rocgrotesk">
                  {benefitsTable[benefits.country].title}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-lg font-rocgrotesk-light text-tangerine">Employee Benefits Summary</p>
                </div>
                <div className="my-4 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
                  {benefitsTable[benefits.country].items.map((item) => item)}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default IndexPage
